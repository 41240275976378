.btn-default{
    @extend .btn-primary;
}


.info__wrap {
    margin-top: 20px;
  }
  .info__wrap p {
    margin-bottom: 5px;
  }
  
  
  .info__wrap .form-group {
    margin-bottom: 0;
  }
  .info__wrap label {
    font-weight: 600;
  }
  
  
  .buttongroup_fix {
    display: flex;
    align-items: center;
  }
  .buttongroup_fix button {
    margin-right: 10px;
  }

  tr.row-system {
    background: #5f5f72;
    color: #fff;
  }
  tr.row-user {
    //background: #fffbd4;
  }
  tr.row-lawyer {
    background: #f2f2fe;
  }  

  .feedback_box {
    display: flex;
    align-items: center;
    background: #fff;
  }

  .fileinputinvalido {
    border: 1px solid #f85032;
    padding: 5px;
    border-radius: 5px;
  }
  
  .infocard__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .infocard__col {
    margin-bottom: 0;
    display: flex;
    
    align-items: center;
  }
  
  
  
  .react-bootstrap-table table {
    table-layout: auto;
  }
  .header__space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .date-filter,
  .date-filter * {
    max-width: 100%;
  }
  .date-filter,
  .number-filter {
    display: flex;
  }
  .date-filter label,
  .number-filter label{
    flex: 1 70%;
    width: 70%;
    max-width: 70%;
  }
  .date-filter label.filter-label,
  .number-filter label.filter-label{
    flex: 1 30%;
    width: 30%;
  }
  
  th > .filter-label,
  th > .filter {
    margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid #f2f2f2;
    width: 100%;
  }
  
  .cr-page__header {
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center !important;;
  }
  
  .table-hover tr {
    cursor: pointer;
  }
  
  .loginform__wrapper {
    max-width: 380px;
    margin: 0 auto;
  }

//NEW
.cr-sidebar--open + .cr-content {
  max-width: calc(100% - 224px);
}
.table-responsive {
  margin-bottom: 30px;
}
.table-responsive th {
  max-width: 240px;
  min-width: 120px;
}
.hidecolumn{
  display: none !important;
}
.divfilter {
  margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid #f2f2f2;
    width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
      align-items: center;
}
.divfilter .react-datepicker-wrapper:first-child{
  margin-right: 5px;
}
.divfilter > input[type="number"] {
  min-width: 70px;
}
.divfilter .react-datepicker-wrapper input{
  max-width: 100px;
  padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  color: #666;
}
.popover {
  max-width: 800px;
}
.btn_fakeinput {
  background: transparent !important;
  color: #666 !important;
  white-space: nowrap;
  max-width: 200px;
  padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.inputSeparator {
  margin-left: 8px;
  margin-right: 8px;
}

.numberCard_title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
}
.numberCard_subtitle {
  font-weight: 600;
}
.inforcard_horizontal {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between;
}
.inforcard_horizontal .infocard_info {
  margin-bottom: 0;
}
.infocard_info {
  margin-bottom: 10px;
}
.card_clickable {
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.card_clickable:hover {
  background: #fcfcfc;
}

//CHAT
//Avatar
.ChatAvatar {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.ChatAvatarPic {
	font-family: serif;
	background-color: #143556;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 14px;
}
.ChatAvatarTxt {
	margin: 0 12px;
	font-size: 14px;
	font-weight: 600;
}
.ChatAvatarPicuser {
	background: #CCAA59;
}
.ChatAvatarPiclawyer {
	background: #143556;
}

//Bubble Text
.ChatBubble {
	max-width: 80%;
}
.ChatBubbleText {
    max-width: 100% !important;
    margin-bottom: 10px;
    *{
        font-family: 'Roboto', sans-serif;
    }
}
.ChatBubbleText >div>div {
	box-shadow: 1px 2px 3px rgba(0,0,0,0.16);
	max-width: 100%;
}
.ChatBubbleright {
    .ChatAvatar {
        flex-direction: row-reverse;
    }
    .ChatBubbleText {
        margin-right: 0 !important;
    }
}